.container {
  background: var(--Primary-Grey-9, #141719);
  overflow: hidden;
  color: var(--Primary-White, #fff);
  position: relative;
}

.container > :global(.widecontainer) {
  display: flex;
  height: calc(100vh - 76px);
  max-height: 800px;
  align-items: stretch;
  position: relative;
}

.leftHalf {
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 50px;
  padding-right: 100px;
}

.leftHalf h1 {
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  margin-bottom: 44px;
}

.highlight {
  color: var(--Primary-Green-3, #d2ed64);
}

.leftHalf p {
  color: var(--Grey-1, #e6e8ea);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 64px;
}

.rightHalf {
  flex: 0 0 45%;
  position: relative;
  padding-top: 60px;
}

.rightHalf > img {
  height: 100%;
  width: auto;
}

.laptopContainer {
  position: absolute;
  bottom: -180px;
}

.rightHalf::after {
  content: "";
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: 0px;
  width: 80%;
  pointer-events: none;
  background: linear-gradient(to right, rgba(20, 25, 24, 0) 0%, #141918 100%);
}

/* Mobile */
@media (max-width: 600px) {
  .container > :global(.widecontainer) {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: unset;
    max-height: unset;
    padding-top: 64px;
    padding-bottom: 0;
    gap: 0px;
  }

  .leftHalf {
    text-align: center;
    padding-bottom: 80px;
    padding-right: 0;
  }

  .leftHalf h1 {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 46px;
    text-align: left;
  }

  .leftHalf p {
    font-size: 16px;
    text-align: left;
  }

  .rightHalf {
    display: flex;
    padding-top: 0;
    margin-right: -20px;
    padding-left: 20px;
  }

  .rightHalf > img {
    width: 200%;
    height: auto;
  }

  .laptopContainer {
    width: 100%;
    right: -20px;
    margin-bottom: -80px;
  }
}
