.container {
  background: var(--Primary-Grey-9, #141719);
  padding: 80px 0px;
}

.container > :global(.widecontainer) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--p-space-12, 48px);
}

.title {
  color: var(--Primary-White, #fff);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  max-width: 516px;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.inputContainer > input {
  border-radius: 4px;
  border-color: #2d2e30;
  background: #2d2e30;
  color: #d5c3b8;
  margin: 0px;
  height: 56px;
  width: 100%;
}

.ctaButton {
  position: absolute;
  right: 8px;
}

.formResponse {
  font-size: 16px;
  font-weight: 500;
  color: #4caf50;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .container {
    padding: 64px 0px;
  }

  .container > :global(.widecontainer) {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 40px;
  }
}
