.container {
  background: var(--Primary-White, #fff);
  padding: 180px 0px;
}

.container > :global(.widecontainer) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--Primary-Grey-9, #141719);
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  max-width: 640px;
  margin-bottom: 24px;
}

.subtitle {
  color: var(--Grey-7, #41464c);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 640px;
  margin-bottom: 96px;
}

.content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.card {
  display: flex;
  padding: 32px 32px 0px 32px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: var(--p-space-6, 24px);
  background: var(--Grey-0, #f8f9f9);
  grid-column: span 2;
  position: relative;
}

.card.wide {
  grid-column: span 4;
}

.card.mid {
  grid-column: span 3;
}

.card.green {
  background: var(--Primary-Green-3, #d2ed64);
}

.cardTitle {
  color: var(--Primary-Grey-9, #141719);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.card > p {
  color: var(--Grey-7, #41464c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 370px;
}

.pillsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: auto;
  margin-bottom: 32px;
}

.pill {
  border-radius: var(--p-space-8, 32px);
  background: var(--Primary-White, #fff);
  box-shadow:
    0px 2px 1px 0px rgba(0, 0, 0, 0.05),
    0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  padding: 4px 12px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
}

.checkMark {
  width: 14px;
  margin-right: 8px;
}

.checkMark > * {
  fill: #a7c93a;
}

.planImg {
  margin-top: auto;
  max-height: 233px;
  align-self: center;
}

.washSaleImg {
  margin-top: auto;
  margin-left: auto;
  max-height: 133px;
  margin-top: 17px;
  margin-bottom: 35px;
}

.taxOptimizerImg {
  margin-left: auto;
  margin-top: auto;
  max-height: 257px;
}

.educationVideoImg {
  position: relative;
  margin-left: auto;
  margin-top: auto;
  bottom: -16px;
  max-height: 169px;
}

.financialGoalImg {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-height: 229px;
  height: auto;
}

.liquidityPlanningImg {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-height: 229px;
}

.scrollContainer {
  display: none;
}

.arrowIcon,
.arrowIconLeft {
  width: 20px;
}

.arrowIcon.disabled > *,
.arrowIconLeft.disabled > * {
  fill: #a3b3c1;
}

.arrowIconLeft {
  transform: rotate(180deg);
}

@media (max-width: 600px) {
  .container {
    padding: 128px 0px;
  }

  .container > :global(.widecontainer) {
    align-items: flex-start;
  }

  .card {
    max-width: 356px;
    min-width: 300px;
  }

  .card p {
    white-space: normal;
  }

  .content {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: calc(100% + 20px);
    white-space: nowrap;
    scroll-behavior: smooth;
    margin-bottom: 0px;
    margin-right: -20px;
    padding-right: 20px;
  }

  .scrollContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
  }

  .title {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 64px;
  }

  .planImg {
    bottom: -56px;
    width: 251px;
    height: 271px;
  }

  .washSaleImg {
    width: 240px;
    height: auto;
  }

  .pillsContainer {
    flex-wrap: wrap;
    gap: 6px;
  }

  .financialGoalImg {
    width: 100%;
    margin-top: auto;
  }

  .liquidityPlanningImg {
    width: 100%;
    margin-top: auto;
  }

  .taxOptimizerImg {
    width: 100%;
  }
}
