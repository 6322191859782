.wrapper {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 160px 0 150px;
}

.title {
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  margin-bottom: 25px;
}

.subtitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 54px;
  font-weight: 400;
  font-size: 16px;
  gap: 32px;
}

.tooltipIcon {
  width: 14px;
  margin-left: 8px;
  margin-bottom: -2px;
}

.tooltipIcon svg {
  stroke: #6f7273;
}

.tooltipContainer {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.tooltipTitle {
  margin-bottom: 10px;
}

.tooltipText {
  color: #6b7075;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.tooltipText a {
  color: #6b7075;
}

.subItem svg {
  width: 22px;
  padding: 2px;
  margin-bottom: -5px;
  margin-right: 8px;
}

.graphContainer svg {
  width: 100%;
}

.securityGraph {
  width: 660px;
  height: 305px;
}

.securityGraphMobile {
  width: 350px;
  height: 163px;
}

/* Mobile */
@media (max-width: 600px) {
  .wrapper {
    padding: 96px 20px;
    align-items: flex-start;
  }
  .title {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 32px;
    text-align: left;
  }

  .subtitleContainer {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: 54px;
  }

  .graphContainer svg {
    width: 100%;
  }
}
