.container {
  background: var(--Grey-0, #f8f9f9);
}

.container > :global(.widecontainer) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 140px;
  padding-bottom: 140px;
}

.content {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-bottom: 20px;
}

.image {
  flex: 0 0 50%;
  width: 50%;
  height: auto;
  border-radius: 24px;
}

.title {
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
}

.subtitle {
  color: var(--Grey-7, #41464c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 48px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 436;
  margin-bottom: 48px;
  gap: 20px;
}

.form input {
  border-radius: var(--Radius-S, 4px);
  border: 1px solid var(--Grey-2, #cfd3d6);
  background: #fff;
  width: 100%;
}

.form label {
  color: var(--Grey-5, #6b7075);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 2px;
}

@media (max-width: 600px) {
  .container > :global(.widecontainer) {
    display: block;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .image {
    width: 100%;
    margin-bottom: 70px;
  }

  .content {
    padding-left: 0;
  }

  .title {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 48px;
  }
}
