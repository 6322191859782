.container {
  background: var(--Grey-0, #f8f9f9);
  padding: 120px 0px;
}

.container > :global(.widecontainer) {
}

.title {
  color: var(--Primary-Grey-9, #141719);
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  max-width: 1040px;
}

.subtitle {
  color: var(--Grey-7, #41464c);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 40px 0px 0px;
}

@media (max-width: 600px) {
  .container {
    padding: 96px 0px;
  }

  .title {
    text-align: left;
    font-size: 30px;
    line-height: 40px;
  }

  .subtitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }
}
