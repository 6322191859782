.container {
  background: #1d2022;
  padding: 180px 0px;
}

.container > :global(.widecontainer) {
  display: flex;
  align-items: center;
  gap: var(--p-space-6, 24px);
}

.title {
  color: var(--Primary-Green-3, #d2ed64);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
}

.contentTitle {
  color: var(--Primary-White, #fff);
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  margin-bottom: 80px;
}

.left {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formImage {
  flex: 0 0 50%;
  width: 50%;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  color: var(--Grey-2, #cfd3d6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  gap: 4px;
  margin-bottom: 80px;
}

.content > p {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0px;
}

.content > p > svg {
  width: 20px;
}
.content > p > svg > * {
  stroke: var(--Grey-2, #cfd3d6);
}

@media (max-width: 600px) {
  .container > :global(.widecontainer) {
    display: block;
  }

  .container {
    padding: 100px 0px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .contentTitle {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 48px;
  }

  .formImage {
    margin-top: 80px;
    width: 100%;
  }
}
