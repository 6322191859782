.container {
  padding: 180px 0px;
  background: var(--Primary-White, #fff);
}

.container > :global(.widecontainer) {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--p-space-6, 24px);
}

.title {
  color: var(--Primary-Grey-9, #141719);
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  text-align: center;
  max-width: 748px;
  text-wrap: wrap;
  margin-bottom: 0px;
}

.content {
  color: var(--Grey-7, #41464c);
  text-align: center;
  max-width: 614px;
}

.userImageContainer {
  width: 860px;
  height: 468px;
  border-radius: var(--p-space-6, 24px);
  background: var(--Success-Green-0, #f5fdd6);
  position: relative;
}

.userImageContainer > img {
  position: absolute;
  right: 80px;
  bottom: 0px;
}

.cardTop,
.cardMiddle,
.cardBottom {
  border-radius: var(--p-space-6, 24px);
  background: var(--Primary-White, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--Primary-Grey-9, #141719);
  width: fit-content;
  position: absolute;
}

.cardTop {
  padding: 38px 94px 44px 51px;
  top: 20px;
  left: 20px;
}

.cardTop p {
  margin: 0px;
}

.cardMiddle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 34px 13px 36px 26px;
  top: 228px;
  left: 296px;
}

.cardMiddle > p {
  max-width: 135px;
  margin: 0px;
}

.cardBottom {
  background: var(--Primary-Green-3, #d2ed64);
  padding: 50px 96px 64px 60px;
  bottom: 20px;
  right: 20px;
}

.cardTitle {
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.disclaimer {
  color: var(--Grey-5, #6b7075);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  max-width: 860px;
}

@media (max-width: 600px) {
  .container {
    padding: 128px 0px;
  }
  .userImageContainer {
    width: 100%;
    height: 190px;
  }

  .content {
    text-align: left;
  }

  .cardTop,
  .cardMiddle,
  .cardBottom {
    border-radius: 7.953px;
  }

  .cardTop {
    padding: 15px 40px 17px 20px;
    top: 20px;
    left: 20px;
  }

  .cardMiddle {
    padding: 14px 5px 14px 10px;
    top: 100px;
    left: 110px;
    max-width: 70px;
  }

  .cardBottom {
    padding: 15px 30px 20px 20px;
    bottom: 7px;
    right: 8px;
    font-size: 25px;
  }

  .title {
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
  }

  .cardTitle {
    font-size: 25.451px;
    font-weight: 400;
  }

  .disclaimer {
    text-align: left;
    font-size: 12px;
    line-height: 18px;
  }

  .cardTop p,
  .cardMiddle p,
  .cardBottom p {
    margin: 0px;
    font-size: 5.567px;
    font-weight: 400;
    line-height: 8.749px;
  }
}
