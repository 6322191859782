.container {
  background: var(--Primary-Grey-9, #141719);
  padding: 120px 0px;
}

.container > :global(.widecontainer) {
  max-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title,
.content,
.carouselArrows {
  max-width: 1080px;
  align-items: center;
  text-align: center;
}

.title {
  color: var(--Primary-Green-3, #d2ed64);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 12px;
}

.content {
  color: var(--Primary-White, #fff);
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  max-width: 753px;
  margin-bottom: 80px;
}

.carousel {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
  scroll-behavior: smooth;
  gap: 20px;
  padding-bottom: 20px;
  margin-left: max((100% - 1080px), 20px);
  padding-right: 450px;
}

.carousel::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 200px;
  width: 400px;
  background: linear-gradient(to right, rgba(20, 25, 24, 0) 0%, #141918 100%);
  pointer-events: none;
}

.carousel::-webkit-scrollbar {
  height: 8px;
}

.carousel::-webkit-scrollbar-thumb {
  background-color: #1e2022;
  border-radius: 10px;
}

.carousel::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.carousel::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 10px;
}

.card {
  border-radius: var(--p-space-6, 24px);
  background: #1d2022;
  position: relative;
}

.card > svg {
  position: relative;
  bottom: 0px;
  width: 360px;
  height: 304px;
}

.cardTitle {
  padding: 40px 40px 0px;
  color: var(--Primary-White, #fff);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 78px;
}

.carouselArrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
  margin-bottom: 80px;
}

.arrowContainer {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowContainer:hover {
  border-radius: 4px;
  background: var(--Grey-8, #2e3238);
}

.arrowIcon,
.arrowIconLeft {
  width: 20px;
  height: 20px;
}

.arrowIcon > *,
.arrowIconLeft > * {
  fill: white;
}

.arrowIcon.disabled > *,
.arrowIconLeft.disabled > * {
  fill: #a3b3c1;
}

.arrowIconLeft {
  transform: rotate(180deg);
}

@media (max-width: 600px) {
  .container {
    padding: 100px 0px 120px;
    width: 100%;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .content {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
  }

  .carousel {
    margin-left: 0px;
    padding-right: 0px;
    width: calc(100% + 20px);
    margin-right: -20px;
    padding-right: 20px;
  }

  .carousel::after {
    width: 50%;
    height: 480px;
    top: 250px;
  }

  .arrowContainer:first-of-type {
    justify-content: flex-start;
  }

  .arrowContainer:last-of-type {
    justify-content: flex-end;
  }
}

@media (max-width: 600px) {
  .carousel::after {
    top: 200px;
  }
}
